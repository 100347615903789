import React, { Component } from 'react';
import { Link } from 'gatsby';
import { css } from "@emotion/core";
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const INITIAL_STATE = {
  email: '',
  error: null,
  //success: 'unicron'
};

const SUCCESS_STATE = {
  email: '',
  success: 'Thank you. An email has been sent to you with instructions to create a password.',
  error: null
};

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
      //alert('Please check your email...');
        this.setState({ ...SUCCESS_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error, success } = this.state;

    const isInvalid = email === '';

    return (
      <form onSubmit={this.onSubmit} className="formForgot">
        <input
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        <button disabled={isInvalid} type="submit" css={css`padding-left:30px!important; padding-right:30px!important;`}>
          SUBMIT
        </button>
        {success && <p className="passwordResetSuccess" css={css`color:#fff!important;padding-left: 20px; padding-right: 20px; margin-top: 1rem; margin-bottom: -1rem;`}>{success}</p>}
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default withFirebase(PasswordForgetForm);

export { PasswordForgetLink };